* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 1rem 2rem;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  background-image: url('./assets/bg.png');
  background-repeat: repeat-y;
  background-size: cover;
}

::-webkit-scrollbar {
  width: 0.6rem;
  background-color: #14213d;
}
::-webkit-scrollbar-thumb {
  background-color: #4539e6;
}
